<template>
  <div>
    <div class="row my-0">
      <div class="col-12">
        <h1 class="mb-4">EDU-DEX</h1>
      </div>
    </div>

    <recess-divider show-line />

    <div class="row steps">
      <div 
        class="col-12" 
        v-for="(step, index) in SHARED.SupportCenterPage.Steps" 
        :key="index"
        :class="{
          'step-with-image': step.image && stepNeedsSpacing(index),
          'step-extra-space': stepExtraSpaceNeeded(index)
        }"
      >
        <p v-html="step.text"></p> 
        <img v-if="step.image" :src="stepImages[step.image]" alt="Step Image" />
      </div>
    </div>
  </div>
</template>

<script>
import picture1 from '../../../shared/src/assets/images/SupportImages/image1.jpg'
import picture2 from '../../../shared/src/assets/images/SupportImages/image2.jpg'
import picture3 from '../../../shared/src/assets/images/SupportImages/image3.jpg'
import picture4 from '../../../shared/src/assets/images/SupportImages/image4.jpg'

// Constants
import { SHARED } from "../../constants/EdumsSharedConstants.json";

export default {
  data() {
    return {
      SHARED,
      stepImages: {
        picture1: picture1,
        picture2: picture2,
        picture3: picture3,
        picture4: picture4
      }
    }
  },
  methods: {
    stepNeedsSpacing(index) {
      return index >= 2 && index <= 7; 
    },
    stepExtraSpaceNeeded(index) {
      return [0, 1, 7, 8].includes(index); 
    }
  }
}
</script>


